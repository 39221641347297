export const projects = [
	{
		projectname: "Glamour",
		projectimages: [
			"https://i.postimg.cc/yYfT1Gfb/Responsive.png",
			"https://i.postimg.cc/g0hyK2GC/home.png",
			"https://i.postimg.cc/TY29Xxcb/user-Dashboard.png",
			"https://i.postimg.cc/rp7n8Hpk/add-Product.png",
			"https://i.postimg.cc/3N1FDf3W/category.png",
			"https://i.postimg.cc/pVJZ7rKx/product.png",
			"https://i.postimg.cc/6qthqxJD/cart.png",
			"https://i.postimg.cc/MGf5rYmr/checkout.png",
			"https://i.postimg.cc/SxT1PRFj/payment.png",
		],
		description:
			"Glamour is an Indian e-commerce company that sells cosmetics and personal care products. ",
		deploymentlink: "https://glamour-ashy.vercel.app/",
		githublink: "https://github.com/pratikderepatil/Glamour",
		techstack: [
			"Axios",
			"JavaScript",
			"React JS",
			"MongoDB",
			"Node JS",
			"Chakra UI",
		],
		tags: ["E-commerce", "Collaborative"],
		features: [
			"All pages are Responsive",
			"Login and Signup ",
			"Role Based Dashboard",
			"Admin can approve or reject the seller",
			"Sort products based on categories, price",
			"Add/Remove Products to cart",
		],
		areaofresponsibility: [
			"Login and Signup Based on role (Admin, Customer, Seller)",
			"Role Based Dashboard",
			"Admin can approve or reject the seller",
			"Admin can view all customers and sellers",
		],
	},
	{
		projectname: "Top Survey",
		projectimages: [
			"https://i.postimg.cc/PxpDqY17/homepage.png",
			"https://i.postimg.cc/JnfkH0qz/login.png",
			"https://i.postimg.cc/VstMQ9fr/signup.png",
			"https://i.postimg.cc/Rhwn7msQ/allsurveys.png",
			"https://i.postimg.cc/Dzzstt0n/createsurvey.png",
			"https://i.postimg.cc/P5yYVttS/displaysurvey.png",
		],
		description:
			"Topsurvey is an online platform that helps to create and run surveys to capture the voices of people who matter to you the most.",
		deploymentlink: "https://topsurvey.vercel.app/",
		githublink: "https://github.com/pratikderepatil/Top-Survey",
		techstack: [
			"Axios",
			"JavaScript",
			"MongoDB",
			"React JS",
			"Node JS",
			"Chakra UI",
		],
		tags: ["Survey Tool", "Collaborative"],
		features: [
			"Landing page for Top Survey",
			"Login and Signup for user account",
			"Password stored in hashed form (user privacy)",
			"Display surveys created by user",
			"Create surveys and view them based on user",
		],
		areaofresponsibility: [
			"Create surveys",
			"Display survey",
			"Backend Deployment on render",
			"Display user specific surveys",
		],
	},
	{
		projectname: "Shop.com",
		tags: ["E-Commerce", "Individual"],

		projectimages: [
			"https://i.postimg.cc/tJ0bzTr9/homepagedark.png",
			"https://i.postimg.cc/Jz7LxSQH/homepagelight.png",
			"https://i.postimg.cc/J7JMzNn0/logindark.png",
			"https://i.postimg.cc/Nfkt1QRw/loginlight.png",
			"https://i.postimg.cc/zXr5bybj/signupdark.png",
			"https://i.postimg.cc/8chSXS3M/signuplight.png",
		],
		description:
			"Shop.com is an e-commerce website that allows purchasing a variety of products.",
		deploymentlink: "https://valid-circle-9091.vercel.app/",
		githublink: "https://github.com/pratikderepatil/valid-circle-9091",
		techstack: ["Chakra UI", "JavaScript", "React JS"],
		features: ["Signup and Login page", "Dark mode for all pages", "Cart page"],
		areaofresponsibility: null,
	},
	{
		projectname: "HubSpot",
		projectimages: [
			"https://i.postimg.cc/bYkNH31k/homepage.png",
			"https://i.postimg.cc/rs1VVwqQ/chatbot.png",
			"https://i.postimg.cc/nrbVfDfX/demoform.png",
			"https://i.postimg.cc/jdhxB2gj/signup.png",
		],
		description:
			"Free CRM (Customer relationship management) for customer support, sales, and marketing with easy-to-use features like live chat, meeting scheduling, and email tracking.",
		deploymentlink: "https://preeminent-bienenstitch-299fc7.netlify.app/",
		githublink: "https://github.com/pratikderepatil/roomy-pet-3292/",
		techstack: ["CSS", "JavaScript", "HTML"],
		tags: ["CRM Tool", "Collaborative"],
		features: [
			"Signup and Login page",
			"Pricing, Marketing, and Contact sales page",
		],
		areaofresponsibility: [
			"Lead the project",
			"Signup and Contact sales page",
			"Chatbot",
		],
	},
	{
		projectname: "KickStarter",
		tags: ["Crowdfunding", "Individual"],

		projectimages: [
			"https://i.postimg.cc/pT6WCzRC/arts.png",
			"https://i.postimg.cc/SssQcDFj/comics.png",
			"https://i.postimg.cc/C5nFQ5Kg/games.png",
			"https://i.postimg.cc/dVP0g2dS/login.png",
			"https://i.postimg.cc/g2V0pxHc/signup.png",
		],
		description:
			"Kickstarter campaigns make ideas into reality. It’s where creators share new visions for creative work with the communities that will come together to fund them.",
		deploymentlink: "https://kickstarter-clone-three.vercel.app/",
		githublink: "https://github.com/pratikderepatil/Kick-Starter",
		techstack: ["Chakra UI", "JavaScript", "React JS"],
		features: ["Signup and Login page", "Landing Page", "Comming Soon page"],
		areaofresponsibility: null,
	},
	{
		projectname: "Deccan Herald",
		projectimages: [
			"https://i.postimg.cc/BvFYy0Wz/responsive.jpg",
			"https://i.postimg.cc/rsjYJN6F/homepage.png",
			"https://i.postimg.cc/Bb4VHwVV/loginpage.png",
			"https://i.postimg.cc/W3MyDPXZ/newsletters.png",
		],
		description:
			"Deccan Herald is an Indian English daily news site. We used HTML, CSS, JavaScript and jQuery in our project. We build this project to gather some real life experience.",
		deploymentlink: "https://deccan-herald.netlify.app/",
		githublink: "https://github.com/pratikderepatil/Deccan-Herald",
		techstack: ["CSS", "JavaScript", "HTML"],
		tags: ["Newspaper", "Collaborative"],
		features: [
			"News exploration with reponsive UI",
			"User registration functionality",
			"Categorized news sections",
		],
		areaofresponsibility: ["News exploration with reponsive UI"],
	},
];
