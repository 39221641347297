export const techstack = [
	{
		language: "HTML",
		image: "https://i.postimg.cc/xdRy0RCR/HTML.png",
	},
	{
		language: "CSS3",
		image: "https://i.postimg.cc/W31mtkqP/CSS3.png",
	},
	{
		language: "JS",
		image: "https://i.postimg.cc/1t7GF3DV/JS.png",
	},
	{
		language: "React JS",
		image: "https://i.postimg.cc/kgWvH9PL/REACTJS.png",
	},
	{
		language: "Chakra UI",
		image: "https://i.postimg.cc/Vs27fvGY/CHAKRAUI.jpg",
	},
	{
		language: "Redux",
		image: "https://i.postimg.cc/RFwPkRvj/REDUX.png",
	},
	{
		language: "Typescript",
		image: "https://i.postimg.cc/yNRXxykn/TYPESCRIPT.png",
	},
	{
		language: "Node JS",
		image: "https://i.postimg.cc/fTtKxm2z/NODEJS.png",
	},
	{
		language: "MongoDB",
		image: "https://i.postimg.cc/7PQ9GmNt/MONGODB.png",
	},
	{
		language: "VS Code",
		image: "https://i.postimg.cc/nLZGb5Hr/VSCODE.png",
	},
	{
		language: "Postman",
		image: "https://i.postimg.cc/Jh7HV3jG/POSTMAN.webp",
	},
	{
		language: "GitHub",
		image: "https://i.postimg.cc/ncy1Trgv/GITHUB.png",
	},
];
