import HomePage from "./Pages/HomePage";
import "./App.css";

function App() {
	return (
		<>
			<HomePage />
		</>
	);
}

export default App;
