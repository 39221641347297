export const experience = [
	{
		title: "Travelopia",
		date: "June 2023 - April 2024",
		subtitle: "Product Engineer - Hybrid",
		logo: true,
		description: [
			"Optimized user interface using React.js, increasing interactivity and resulting in improved customer satisfaction. Engineered a Two-Factor Authentication (2FA) system with a QR code mechanism, enhancing security protocols and reducing potential security breaches.",
			"Streamlined data extraction processes using MongoDB, facilitating efficient CSV creation for marketing analysis.",
			"Implemented Test-Driven Development (TDD) practices to ensure high-quality code, resulting in a decrease in postrelease bugs and an increase in overall product stability.",
			"Utilized CI/CD processes to automate testing and deployment tasks, leading to an increase in development efficiency and an improvement in overall product performance.",
			"Tech Stack: React JS, Redux, TypeScript, JavaScript, Jest, Element UI, HTML, CSS, MongoDB, GitHub",
		],
	},
	{
		title: "Masai School",
		date: "April 2022 - January 2023",
		subtitle: "Student (Remote)",
		logo: false,
		description: [
			"Completed a Full-Stack Web Development course with expertise in front-end and back-end technologies.",
			"Developed strong skills in HTML, CSS, JavaScript, React.js, Node.js, Express.js, and MongoDB.",
			"Successfully built responsive web applications while adhering to industry best practices and agile methodologies.",
			"Collaborated effectively with peers on various projects, refining code quality through thorough reviews.",
			"Proficient in Git and GitHub for version control, ensuring seamless team collaboration.",
			"Acquired valuable problem-solving skills through debugging and implementing effective solutions.",
			"Tech Stack: HTML, CSS, JavaScript, React.js, Redux, Node.js, MongoDB.",
		],
	},
	{
		title: "Bajaj Finserv",
		date: "July 2021 - March 2022",
		subtitle: "Project Manager Trainee, Pune, Maharashtra",
		logo: true,
		description: [
			"Presented detailed project updates to key stakeholders on strategic initiatives, showcasing progress metrics and highlighting milestones achieved, increasing stakeholder engagement and satisfaction.",
			"Managed Content Fragments within the AEM platform to streamline authoring processes, enabling efficient content maintenance and reuse across various digital channels for seamless omnichannel experiences, resulting in an improvement in content consistency and quality.",
		],
	},
	{
		title: "Code Gurukul Pvt Ltd",
		date: "December 2020 - June 2021",
		subtitle: "Intern (Remote)",
		logo: true,

		description: [
			"Implemented puzzle games for children, improving their understanding of data structures and algorithms.",
			"Specialized in utilizing Blockly for creating interactive and dynamic web pages.",
			"Collaborated with team members to optimize web page performance.",
			"Tech Stack: Blockly, Javascript, HTML, CSS, Github.",
		],
	},
	{
		title: "M.Sc Computer Application",
		logo: false,
		date: "June 2019 - March 2021",
		subtitle: "Fergusson College, Pune, Maharashtra",
		description: [],
	},
	{
		logo: false,
		title: "JAVA Certification",
		date: "July 2018 - February 2019",
		subtitle: "SEED Infotech, Pune, Maharashtra",
		description: [],
	},
	{
		title: "B.Sc Computer Science",
		logo: false,
		date: "June 2015 - October 2018",
		subtitle: "MIT College, Pune, Maharashtra",
		description: [],
	},
];
